// Entry point for the build script in your package.json
/* eslint no-console:0 */
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo
// Start rails-ujs
import Rails from '@rails/ujs';
Rails.start();

import { Application } from 'stimulus'
import StimulusControllerResolver from 'stimulus-controller-resolver'

const application = Application.start()

import BodyController from './controllers/body_controller.js'
import BootstrapController from './controllers/bootstrap_controller.js'

application.register('body_controller', BodyController)
application.register('bootstrap_controller', BootstrapController)

async function customResolver(controllerName) {
  const logicalName = controllerName.replace(/--/g, "/").replace(/-/g, "_")
  console.log("Requesting: ", logicalName);
  const controller = await import(`./controllers/${logicalName}_controller.js`)
  return controller.default
}

import ahoy from "ahoy.js"
ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
StimulusControllerResolver.install(application, customResolver)

// Compile css
import "@fortawesome/fontawesome-free/css/all";
import "@fortawesome/fontawesome-free/js/all";
import "@fontsource/merriweather-sans/300.css";
import './css/site.scss';
import './css/actiontext.scss';
import 'tippy.js/dist/tippy.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';


// Get images
// const images = require.context('../assets/images', true)
